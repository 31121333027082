import styled from "styled-components"
import React from "react"
import { BREAKPOINTS } from "../../constants"
import { Tag } from "../projectListing/ProjectContent"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"

import CalenderIcon from "../../../static/website_assets/CalendarBlank.svg"
import TagIcon from "../../../static/website_assets/Tag.svg"
import HourglassIcon from "../../../static/website_assets/Hourglass.svg"
import ArrowUpIcon from "../../../static/website_assets/ArrowUpRight.svg"
import { motion } from "framer-motion"
// import { GatsbyImage } from "gatsby-plugin-image"

export const PostsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  > * + * {
    margin: 1rem 0 0 0;
  }
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    margin: 2rem 1rem 1rem 1rem;
  }
  @media (max-width: 1080px) and (min-width: ${BREAKPOINTS["small-viewport"]}) {
    margin: var(--safety-padding);
  }
`

export const Post = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: var(--color-background-48-dp-elevation);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  justify-content: flex-start;
  text-align: start;
  transition: filter 240ms ease-in-out;
  > * + * {
    margin: 0;
    margin-top: 1rem;
  }
  :hover {
    filter: drop-shadow(8px 8px 8px rgb(0 0 0 / 12%)) saturate(120%);
  }
`

export const Title = styled.h3`
  margin: 0;
  font-size: var(--heading-4);
  line-height: calc(var(--heading-4) * 1.2);
  letter-spacing: 0.024em;
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    font-size: var(--heading-1);
    line-height: calc(var(--heading-1) * 1.2);
    letter-spacing: 0.024em;
  }
  cursor: pointer;
  transition: color 240ms ease-in-out;
  :hover {
    color: var(--color-theme-accent);
  }
`
export const Published = styled.p`
  font-size: var(--tags);
  opacity: 0.72;
  :before {
    content: ".";
    display: inline-block;
    margin-right: 0.6rem;
    height: calc(var(--tags)+"4px");
    width: 20px;
    background-color: var(--color-theme-text);
    --webkit-mask: url(${CalenderIcon}) no-repeat center;
    mask: url(${CalenderIcon}) no-repeat center;
  }
`
export const TimeToRead = styled.p`
  font-size: var(--tags);
  opacity: 0.72;
  padding: 0.5rem 0;
  :before {
    content: ".";
    display: inline-block;
    margin-right: 0.6rem;
    height: calc(var(--tags)+"4px");
    width: 20px;
    background-color: var(--color-theme-text);
    --webkit-mask: url(${HourglassIcon}) no-repeat center;
    mask: url(${HourglassIcon}) no-repeat center;
  }
`
export const Description = styled.p``

export const Tags = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
  > * {
    margin: 0 0.5rem 0 0;
  }
  :before {
    opacity: 0.72;
    content: "";
    display: inline-block;
    margin-right: 0.6rem;
    height: 23px;
    width: 20px;
    background-color: var(--color-theme-text);
    --webkit-mask: url(${TagIcon}) no-repeat center;
    mask: url(${TagIcon}) no-repeat center;
  }
`
export const ReadMore = styled(Link)`
  font-family: "Work Sans", sans-serif;
  font-size: var(--paragraph);
  line-height: calc(var(--paragraph) * 1.4);
  font-weight: bold;
  letter-spacing: 0.032em;
  color: var(--color-theme-text);
  width: fit-content;
  transition: color 240ms ease-in-out;
  :hover {
    color: var(--color-theme-accent);
    :after {
      background-color: var(--color-theme-accent);
    }
  }
  :after {
    content: ".";
    display: inline-block;
    margin-left: 0.2rem;
    height: calc(var(--paragraph)+"4px");
    width: 20px;
    transition: background-color 240ms ease-in-out;
    background-color: var(--color-theme-text);
    --webkit-mask: url(${ArrowUpIcon}) no-repeat center;
    mask: url(${ArrowUpIcon}) no-repeat center;
  }
`
export const MetaFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "space-between"};
  > * + * {
    margin-left: ${props => (props.justifyContent ? "1rem" : 0)};
  }
  align-items: center;
  flex-wrap: wrap;
  padding: ${props => (props.pad ? "2rem 0 1rem 0" : 0)};
`
const CustomLink = styled(Link)`
  color: var(--color-theme-secondary-accent);
`

// const BackgroundImage = styled(GatsbyImage)`
//   width: 100%;
//   height: 100%;
//   position: absolute;
// `

export const Posts = props => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { frontmatter: { type: { eq: "article" } } }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        nodes {
          slug
          excerpt(pruneLength: 280)
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
            type
            tags
            coverImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          timeToRead
          id
        }
      }
    }
  `)

  let postsData = undefined

  if (!props.postsData) {
    postsData = props.limit
      ? data.allMdx.nodes.slice(0, props.limit)
      : data.allMdx.nodes
  } else {
    postsData = props.postsData
  }

  const cardVariants = {
    offscreen: {
      opacity: 0,
      y: 20,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  }

  return (
    <PostsWrapper>
      {postsData.map(post => {
        const tagsArrayLength = post.frontmatter.tags.length
        return (
          <Post
            key={post.id}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.3 }}
            variants={cardVariants}
          >
            {/* <BackgroundImage
              alt={post.frontmatter.title}
              image={post.frontmatter.coverImage.childImageSharp.gatsbyImageData}
            ></BackgroundImage> */}
            <Title
              onClick={() => {
                navigate(`/blog/${post.slug.split("/")[1]}`)
              }}
            >
              {post.frontmatter.title}
            </Title>
            <MetaFlex>
              <Published>{post.frontmatter.date}</Published>
              <TimeToRead>{`${post.timeToRead} minute${
                post.timeToRead > 1 ? "s" : ""
              }`}</TimeToRead>
            </MetaFlex>
            <Description>{post.excerpt}</Description>
            {post.frontmatter.tags && (
              <Tags>
                {post.frontmatter.tags.map((tag, index) => {
                  const comma = index !== tagsArrayLength - 1 ? "," : ""
                  return (
                    <Tag key={`${post.id}-${tag}`}>
                      <Link to={`/tags/${tag}`}>
                        {tag}
                        {comma}
                      </Link>
                    </Tag>
                  )
                })}
              </Tags>
            )}
            <ReadMore
              to={`/blog/${post.slug.split("/")[1]}`}
              alt={`View this blog post on ${post.frontmatter.title}.`}
            >
              Continue reading
            </ReadMore>
          </Post>
        )
      })}
      {!props.postsData && props.limit && (
        <CustomLink to="/blog">More posts</CustomLink>
      )}
    </PostsWrapper>
  )
}

export default Posts
