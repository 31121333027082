import { graphql } from "gatsby"
import React from "react"
import { SectionHeader } from "../components/Common"
import Head from "../components/head"
import Layout from "../layouts/layout"
import Projects from "../components/projectListing/Projects"
import Posts from "../components/blogList/Posts"
import styled from "styled-components"

export const tagsData = graphql`
  query($tag: String!) {
    allMdx(filter: { frontmatter: { tags: { eq: $tag } } }) {
      nodes {
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          tags
          type
          locked
          description
          externalLinks {
            figma
            website
          }
          coverImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        excerpt
        slug
        id
        timeToRead
      }
    }
  }
`
function TagsListPage(props) {
  const projectsData = props.data.allMdx.nodes.filter(
    node => node.frontmatter.type === "project"
  )
  const postsData = props.data.allMdx.nodes.filter(
    node => node.frontmatter.type === "article"
  )

  const AccentHighlight = styled.span`
    color: var(--color-theme-accent);
  `

  return (
    <Layout>
      <Head title={`Tag: ${props.pageContext.tag}`} />
      <SectionHeader>
        All Projects and Posts under:{" "}
        <AccentHighlight>{props.pageContext.tag}</AccentHighlight>
      </SectionHeader>
      {projectsData.length > 0 && (
        <>
          <h3>Projects</h3>
          <Projects projectsData={projectsData} />
        </>
      )}
      {postsData.length > 0 && (
        <>
          <h3>Posts</h3>
          <Posts postsData={postsData} />
        </>
      )}
    </Layout>
  )
}

export default TagsListPage
